import React, { useState,useEffect } from 'react';
import TableWrapper from "./../components/Table";
import NavbarCustom from "./../components/NavbarCustom";
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import '../../node_modules/react-vis/dist/style.css';
import { XYPlot, RadialChart, DiscreteColorLegend } from 'react-vis';
import { useAuth } from '../util/auth';
import { useRouter } from "./../util/router.js";
import { Spinner } from 'react-bootstrap'

var colors = ['#ef5d28', '#0bbd3b', '#850bbd', '#79c7e3', '#f1f507'];
function ReportsPage(props){
  const auth = useAuth();
  const router = useRouter();

  const [mode, setMode] = useState("monthly");
  const [subscriptions, setSubscriptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isMounted, setIsMounted] = useState(false)


  const [sortState, setSortState] = useState({
    AmountSort: "ascending",
    nameSort: "ascending",
    isInitialSort: true
  });

  const toggleAndSaveSort = (event, keyName) => {
    const stateKeyName = keyName + "Sort";
    let newSortState = toggleSort(sortState[stateKeyName]);
    onSort(event, keyName, sortState[stateKeyName])
    sortState[stateKeyName] = newSortState;
    sortState.isInitialSort = stateKeyName;
    setSortState(sortState);
  }

  const toggleSort = (currentSortMode) => {
    if (currentSortMode === "ascending") {
      return "descending";
    } else if (currentSortMode === "descending") {
      return "ascending";
    }
  }

  useEffect(() => {
    setIsMounted(true)
  }, [])

  const onSort = (event, sortKey, mode) => {
    const sortedSubscriptions = [...subscriptions];
    if (sortKey === "name") {
      if (mode === "descending")
      sortedSubscriptions.sort((a, b) => (a[sortKey].toString()).localeCompare(b[sortKey]).toString())
      else if (mode === "ascending")
      sortedSubscriptions.sort((a, b) => (b[sortKey].toString()).localeCompare(a[sortKey]).toString())
    }
    else if (sortKey === "Amount") {
      if (mode === "descending")
      sortedSubscriptions.sort((a, b) => getAnnualAmount(b[sortKey], b["renewal"]) - getAnnualAmount(a[sortKey],a["renewal"]))
      else if (mode === "ascending")
      sortedSubscriptions.sort((a, b) => getAnnualAmount(a[sortKey],a["renewal"]) - getAnnualAmount(b[sortKey],b["renewal"] ))
    }
    setSubscriptions(sortedSubscriptions);
  }

  const getAnnualAmount = (amount ,renewalMode) => {
      if(renewalMode==="Monthly")
        return amount*12;
      else
        return amount;
  }

  const apiRequest = (callback, shouldHideLoading, isLoadingNeeded) => {
    if(isLoadingNeeded){
      setIsLoading(true);
    }
    auth.user.getIdToken().then(
      (idToken) => { callback(idToken) }
    ).catch(function (error) {
        if(process.env.NODE_ENV !== "production"){
          console.log("error in getIdToken")
        }
      }).finally(
        () => {
          if(isLoadingNeeded && shouldHideLoading)
            setIsLoading(false)
      });
  }

  const getSubs = (idToken) => {
    fetch(
      "https://happydebits.com/.netlify/functions/index/getUserSubs", {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'auth': idToken
      },
      method: 'get'
    }
    )
      .then(res => res.json())
      .then(result => {
        setSubscriptions(result);
      })
      .catch(e => {
        if(process.env.NODE_ENV !== "production"){
          console.log("error", e);
        }
      }).finally(()=>{
        setIsLoading(false)
      });
  }

  const syncToServer = (idToken) => {
    fetch(
      "https://happydebits.com/.netlify/functions/index/saveUserSubs",
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'auth': idToken
        },
        method: 'post',
        body: JSON.stringify({"subscriptions":subscriptions})
      }
    )
      .then(res => res.json())
      .then(result => {
        //setSubscriptions(result);
      })
      .catch(e => {
        if(process.env.NODE_ENV !== "production"){
          console.log(e);
        }
      });
  }
  

  useEffect(() => { 
      if (auth && auth.user) {
        apiRequest(getSubs,true,false);
      }else if(isMounted){
        router.push('/auth/signin');
    }
  }, [auth]);

  useEffect(() => {
    if(auth && auth.user && isMounted){
      apiRequest(syncToServer,true,false);
    }
    }, [subscriptions]); 

  
  const onModeSwitch = selection => {
    if (selection.value == 'Monthly costs') {
      setMode("monthly");
    }
    else if (selection.value == 'Annual costs') {
      setMode("annual");
    }
  }


  const getSubscriptionByMode = (subscriptions, mode) => {
    if (mode == undefined || mode == "") {
      mode = "monthly";
    }
    let result = subscriptions.map((sub, index) => {
      let obj = {};
      obj.name = sub.name;
      if (mode == "annual" && sub.renewal == "Monthly")
        obj.Amount = sub.Amount * 12;
      else if (mode == "monthly" && sub.renewal == "Annual")
        obj.Amount = sub.Amount / 12;
      else {
        obj.Amount = sub.Amount;
      }
      return obj;
    });

    return result;
  }

  const getChartData = subscriptions => {
    let topSubs = [];
    topSubs = subscriptions.sort((a, b) => b["Amount"] - a["Amount"]).slice(0, 5);
    let total = 0;
    topSubs.forEach(function (sub) {
      total += sub.Amount;
    })
    let data = [];
    let chartLegendData = [];
    topSubs.forEach(function (sub, index) {
      let newSubForData = {};
      let newSubForLegendData = {};
      newSubForData.label = sub.name;
      newSubForData.angle = sub.Amount / total;
      newSubForData.color = colors[index];
      newSubForLegendData.title = sub.name;
      newSubForLegendData.color = colors[index];
      newSubForLegendData.strokeWidth = 15;
      data.push(newSubForData);
      chartLegendData.push(newSubForLegendData);
    })
    let resultData = {};
    resultData.data = data;
    resultData.chartLegendData = chartLegendData;
    return resultData;
  }

    const options = [
      'Monthly costs', 'Annual costs'
    ]
    let defaultOption = options[0]
   /*  if (subscriptions===undefined || subscriptions.length === 0) {
      window.location = "/manage";
      return;
    } */
    // let mode  = this.props.match.params.mode;
    let modeSubscription = getSubscriptionByMode(subscriptions, mode);
    let chartData = [];
    let chartLegendData = [];
    if (modeSubscription !== undefined) {
      let resultData = getChartData(modeSubscription);
      chartData = resultData.data;
      chartLegendData = resultData.chartLegendData;
    }

    let isAnnual = false;
    if (mode == "annual") {
      defaultOption = options[1]
      isAnnual = true;
    } else if (mode == "monthly") {
      isAnnual = false;
      defaultOption = options[0]
    }
    let loadingContent =<> <div className="SpinnerContainer vertical-center justify-content"><Spinner className="SpinnerMargin" animation="border" role="status"/></div><span className="sr-only">Loading...</span></>
    let mainContent = <>
    <div className="text-center">
    <h5 className="margin-1-all">Top 5 subscription expenses</h5>
    <div id="chartsHolder" className="inline-block justify-content vertical-center">
      <RadialChart className="float-left clear-none" width={350}
        height={350}
        data={chartData}
        colorType={'literal'}
        labelsRadiusMultiplier={0.8}
        labelsStyle={{
          fontSize: 13
        }}
      // showLabels
      />
      <DiscreteColorLegend
        className="float-left clear-none"
        //height="auto"
        width={350}
        items={chartLegendData}
      />
    </div>
  </div>
  <div className="vertical-center margin-1-all">
    <h5 className="inline-block margin-right-1">All subscriptions</h5>
    <Dropdown options={options} className="inline-block" onChange={onModeSwitch} value={defaultOption} placeholder="Select Renewal type" />
  </div>
  <TableWrapper
    subscriptionData={subscriptions} isAnnual={isAnnual} toggleAndSaveSort={toggleAndSaveSort} isManageMode={false} sortState={sortState}
  />
  </>; 
  let finalContent = isLoading?loadingContent:mainContent;
    return (
      <>
      <NavbarCustom
        bg="primary"
        variant="light"
        expand="md"
        logo="assets/HappyDebits.png"
      ></NavbarCustom>
      <div className="container">
        {finalContent}
        </div>
      </>
    );
  }

export default ReportsPage;
