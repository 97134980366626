import React, { useState, useEffect } from "react";
import "./../styles/global.scss";
import IndexPage from "./index";
import AuthSigninPage from "./authsignin";
import AuthSignupPage from "./authsignup";
import ForgotPasswordPage from "./forgotpassword";
import ChangePasswordPage from "./changepassword";
import HelpPage from "./help";
import BlogPage from "./blog";
import ReportsPage from "./ReportsPage";
import ManagePage from "./ManagePage";
import { Switch, Route, Router, Redirect } from "./../util/router.js";
import "./../util/analytics.js";
import { ProvideAuth, useAuth } from "./../util/auth.js";


function App(props) {
  

  return (
    <ProvideAuth>
      <Router>
        <Switch>
          <Route exact path="/" component={IndexPage} />

          <Route exact path="/auth/signin" component={AuthSigninPage} />

          <Route exact path="/auth/signup" component={AuthSignupPage} />

          <Route exact path="/auth/forgotpass" component={ForgotPasswordPage} />

          {/* <Route exact path="/auth/changepassword" component={ChangePasswordPage} /> */}

          <Route exact path="/dashboard" render={(props) => <ReportsPage {...props} />} />
          
          <Route exact path="/manage" render={(props) => <ManagePage {...props} />} />

          <Route
            component={({ location }) => {
              return (
                <div
                  style={{
                    padding: "50px",
                    width: "100%",
                    textAlign: "center"
                  }}
                >
                  The page <code>{location.pathname}</code> could not be found.
                </div>
              );
            }}
          />
        </Switch>
      </Router>
    </ProvideAuth>
  );
}

          /* <Route exact path="/help" component={HelpPage} />

          <Route exact path="/blog" component={BlogPage} /> */
export default App;
