import React from "react";
import {useEffect} from 'react';
import NavbarCustom from "./../components/NavbarCustom";
import AuthSection from "./../components/AuthSection";
import { useAuth } from '../util/auth';
import { useRouter } from "./../util/router.js";

function AuthSigninPage(props) {
  const auth = useAuth();
  const router = useRouter();

  useEffect(() => { 
    if (auth && auth.user) {
        router.push('/dashboard');
     }
  }, [auth]);

  return (
    <>
      <NavbarCustom
        bg="light"
        variant="light"
        expand="md"
        logo="/assets/HappyDebits.png"
      ></NavbarCustom>
      <AuthSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        inputSize="lg"
        type="signin"
        providers={["google", "facebook"]}
        afterAuthPath="/dashboard"
      ></AuthSection>
    </>
  );
}

export default AuthSigninPage;
