import React, {useState,useEffect} from 'react';
import TableWrapper from "./../components/Table";
import Form from "./../components/Form";
import NavbarCustom from "./../components/NavbarCustom";
import { useAuth } from '../util/auth';
import { Spinner } from 'react-bootstrap'
import { useRouter } from "./../util/router.js";

function ManagePage(props){
    const auth = useAuth();
    const router = useRouter();

    const [subscriptions, setSubscriptions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isMounted, setIsMounted] = useState(false)

    const [sortState, setSortState] = useState({
      AmountSort: "ascending",
      nameSort: "ascending",
      isInitialSort: true
    });
  
    const toggleAndSaveSort = (event, keyName) => {
      const stateKeyName = keyName + "Sort";
      let newSortState = toggleSort(sortState[stateKeyName]);
      onSort(event, keyName, sortState[stateKeyName])
      sortState[stateKeyName] = newSortState;
      sortState.isInitialSort = stateKeyName;
      setSortState(sortState);
    }

    const toggleSort = (currentSortMode) => {
      if (currentSortMode === "ascending") {
        return "descending";
      } else if (currentSortMode === "descending") {
        return "ascending";
      }
    }


    useEffect(() => {
      setIsMounted(true)
    }, [])

    const onSort = (event, sortKey, mode) => {
      const sortedSubscriptions = [...subscriptions];
      if (sortKey === "name") {
        if (mode === "descending")
        sortedSubscriptions.sort((a, b) => (a[sortKey].toString()).localeCompare(b[sortKey]).toString())
        else if (mode === "ascending")
        sortedSubscriptions.sort((a, b) => (b[sortKey].toString()).localeCompare(a[sortKey]).toString())
      }
      else if (sortKey === "Amount") {
        if (mode === "descending")
        sortedSubscriptions.sort((a, b) => getAnnualAmount(b[sortKey], b["renewal"]) - getAnnualAmount(a[sortKey],a["renewal"]))
        else if (mode === "ascending")
        sortedSubscriptions.sort((a, b) => getAnnualAmount(a[sortKey],a["renewal"]) - getAnnualAmount(b[sortKey],b["renewal"] ))
      }
      setSubscriptions(sortedSubscriptions);
    }
  
    const getAnnualAmount = (amount ,renewalMode) => {
        if(renewalMode==="Monthly")
          return amount*12;
        else
          return amount;
    }

    const handleSubmit = subscription => {
      setSubscriptions([...subscriptions, subscription]);
    }

    const removeSubscription = index => {
      const newsub = subscriptions.filter((subscription, i) => {
        return i !== index;
      })
      setSubscriptions(newsub);
      
    }

    const apiRequest = (callback, shouldHideLoading) => {
      setIsLoading(true);
      auth.user.getIdToken().then(
        (idToken) => { callback(idToken) }
      ).catch(function (error) {
          if(process.env.NODE_ENV !== "production"){
            console.log("error in getIdToken")
          }
        }).finally(
            () => {
              if(shouldHideLoading)
                setIsLoading(false)
          });
    }

    const getSubs = (idToken) => {
      fetch(
        "https://happydebits.com/.netlify/functions/index/getUserSubs", {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'auth': idToken
        },
        method: 'get'
      }
      )
        .then(res => res.json())
        .then(result => {
          setSubscriptions(result);
        })
        .catch(e => {
          if(process.env.NODE_ENV !== "production"){
            console.log("error", e);
          }
        });
    }
  
    const syncToServer = (idToken) => {
      fetch(
        "https://happydebits.com/.netlify/functions/index/saveUserSubs",
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'auth': idToken
          },
          method: 'post',
          body: JSON.stringify({"subscriptions":subscriptions})
        }
      )
        .then(res => res.json())
        .then(result => {
          //setSubscriptions(result);
        })
        .catch(e => {
          if(process.env.NODE_ENV !== "production"){
            console.log(e);
          }
        });
    }

    useEffect(() => { 
        if (auth && auth.user) {
          apiRequest(getSubs,false);
        }else if(isMounted){
            router.push('/auth/signin');
        }
    }, [auth]);

     useEffect(() => {
      if(auth && auth.user && isMounted){
        apiRequest(syncToServer, true);
      }
     }, [subscriptions]); 
  
    let displayData = "";
    if (subscriptions===undefined || subscriptions.length === 0) {
      displayData = <div className="margin-bottom margin-top"><b>Get started by adding new subscriptions below</b></div>;
    } else {
      displayData = <><h5 className="margin-1-all">Your Subscriptions</h5> <TableWrapper toggleAndSaveSort = {toggleAndSaveSort} isManageMode={true} subscriptionData={subscriptions} removeSubscription={removeSubscription} sortState={sortState}/></>;
    }
    let loadingContent =<> <div className="SpinnerContainer vertical-center justify-content"><Spinner className="SpinnerMargin" animation="border" role="status"/></div><span className="sr-only">Loading...</span></>
    let mainContent = <>{displayData} <Form handleSubmit={handleSubmit} /></>
    if(isLoading){
      mainContent = loadingContent
    }
    return (
      <>
      <NavbarCustom
        bg="primary"
        variant="light"
        expand="md"
        logo="assets/HappyDebits.png"
      ></NavbarCustom>
      
      <div className="container">
        {mainContent}
        </div>
      </>
    );
}

export default ManagePage;
