import React from "react";
import NavbarCustom from "./../components/NavbarCustom";
import HeroSection from "./../components/HeroSection";
import FeaturesSection from "./../components/FeaturesSection";
import CtaSection from "./../components/CtaSection";
import Footer from "./../components/Footer";
import { useRouter } from "./../util/router.js";
import { useAuth } from '../util/auth';

function IndexPage(props) {
  const router = useRouter();
  const auth = useAuth();

  return (
    <>
      <NavbarCustom
        bg="light"
        variant="light"
        expand="md"
        // logo="https://uploads.divjoy.com/logo.svg"
        logo="assets/HappyDebits.png"
      ></NavbarCustom>
      <HeroSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Simple subscription budgeting"
        subtitle="Save, track and visualize your subscriptions and recurring expenses in one place."
        buttonText="Get Started"
        buttonColor="primary"
        image="/assets/subscription_management_tracking_main.svg"
        buttonOnClick={() => {
          if (auth && auth.user) {
            router.push("/dashboard");
          }else{
            router.push("/auth/signup");
          }
        }}
      ></HeroSection>
      <FeaturesSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        // Navigate the subscription economy
        title="Your personal subscription manager"
        subtitle="Happydebits helps you create and manage a subscription budget, giving you invaluable insights and savings. In today's world of subscriptions, having a subscription budget gives you strong control over your total expenditure."
      ></FeaturesSection>
      <CtaSection
        bg="white"
        textColor="dark"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title="Fall in love with your subscriptions"
        subtitle=""
        buttonText="Get Started"
        buttonColor="primary"
        buttonOnClick={() => {
          if (auth && auth.user) {
            router.push("/dashboard");
          }else{
            router.push("/auth/signup");
          }
        }}
      ></CtaSection>
      <Footer
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        description="A short description of what you do here"
        copyright="© 2020 Happydebits"
        logo="assets/HappyDebits.png"
      ></Footer>
    </>
  );
}

export default IndexPage;
