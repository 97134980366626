import React, { useState, useEffect, useContext, createContext } from "react";
import queryString from "query-string";
import * as firebase from "firebase/app";
import "firebase/auth";

if (!firebase.apps.length) {
  // Replace with your own Firebase credentials
  firebase.initializeApp({
    apiKey: "AIzaSyADiIxrB0BYoC2MkCSHJyILl8RQGsAGfWA",
    authDomain: "subtracker-b4d89.firebaseapp.com",
    projectId: "subtracker-b4d89",
    appID: "subtracker-b4d89"
  });
}

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and update when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null);

  const signup = (email, password) => {
    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(response => {
        setUser(response.user);
        return response.user;
      });
  };

  const signin = (email, password) => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(response => {
        setUser(response.user);
        return response.user;
      });
  };

  const signinWithProvider = providerName => {
    const providerOptions = {
      google: {
        providerMethod: firebase.auth.GoogleAuthProvider
      },
      facebook: {
        providerMethod: firebase.auth.FacebookAuthProvider,
        parameters: {
          // Tell fb to show popup size UI instead of full website
          display: "popup"
        }
      },
      twitter: {
        providerMethod: firebase.auth.TwitterAuthProvider
      },
      github: {
        providerMethod: firebase.auth.GithubAuthProvider
      }
    };

    const { providerMethod, parameters } = providerOptions[providerName];

    const provider = new providerMethod();
    if (parameters) {
      provider.setCustomParameters(parameters);
    }

    return firebase
      .auth()
      .signInWithPopup(provider)
      .then(response => {
        setUser(response.user);
        return response.user;
      });
  };

  const signout = () => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(false);
      });
  };

  const sendPasswordResetEmail = email => {
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        return true;
      });
  };

  const confirmPasswordReset = (password, code) => {
    // Get code from query string object
    const resetCode = code || getFromQueryString("oobCode");

    return firebase
      .auth()
      .confirmPasswordReset(resetCode, password)
      .then(() => {
        return true;
      });
  };

  // Subscribe to user on mount
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setUser(user);
      } else {
        setUser(false);
      }
    });
    // Subscription unsubscribe function
    return () => unsubscribe();
  }, []);

  return {
    user,
    signup,
    signin,
    signinWithProvider,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset
  };
}

const getFromQueryString = key => {
  return queryString.parse(window.location.search)[key];
};
