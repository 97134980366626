import React from "react";
import NavbarCustom5 from "./../components/NavbarCustom5";
import HeroSection2 from "./../components/HeroSection2";

function BlogPage(props) {
  return (
    <>
      <NavbarCustom5
        bg="white"
        variant="light"
        expand="md"
        logo="https://uploads.divjoy.com/logo.svg"
      ></NavbarCustom5>
      <HeroSection2
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Coming soon"
        subtitle="Happydebits blog is in the works. Please check back soon! :)"
      ></HeroSection2>
    </>
  );
}

export default BlogPage;
