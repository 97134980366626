import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import { Link } from "./../util/router.js";
import { useAuth } from "./../util/auth.js";
import "./Footer.scss";

function Footer(props) {
  const auth = useAuth();

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <div className="FooterComponent__inner">
          <div className="brand left">
            <Link to="/">
              <img src={props.logo} alt="Logo"></img>
            </Link>
          </div>
          <div className="links right">
            <Link target="_blank" to="/auth/signin" rel="noopener noreferrer">
              Get started
            </Link>
          </div>
          <div className="social right">
            
          </div>
          <div className="copyright left">{props.copyright}</div>
        </div>
      </Container>
    </Section>
  );
}
   /* <Link to="/blog">Blog</Link>
            <Link to="/help">Help</Link> */
export default Footer;
