import React, { Component } from 'react';
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'

class Form extends Component {
    constructor(props) {
        super(props);

        this.initialState = {
            name: '',
            Amount: 0.0,
            renewal: 'Monthly',
            error: ""
        };

        this.state = this.initialState;
    }

    onRenewalType = option => {
        this.setState({ renewal: option.value })
    }

    handleChange = event => {
        let { name, value } = event.target;
        if (value === "") {
            this.setState({
                error: "Subscription name and amount can't be empty"
            });
        }
        if (name === "Amount" && value === event.target.value) {
            if (this.state.error == "") {
                value = parseFloat(event.target.value);
                this.setState({
                    [name]: value,
                    error: ""
                });
            }
        } else {
            this.setState({
                [name]: value,
                error: ""
            });
        }
    }

    onFormSubmit = (event) => {
        event.preventDefault();
        let { name, value } = event.target;
        if (name === "" || value === "") {
            this.setState({
                error: "Subscription name and amount can't be empty"
            });
        }
        if (this.state.error === "") {
            var subData = {};
            subData.name = this.state.name
            subData.Amount = this.state.Amount
            subData.renewal = this.state.renewal
            this.props.handleSubmit(subData);
            this.setState(this.initialState);
        } else {
            alert("Please resolve the errors before submitting")
        }

    }

    render() {
        const { name, Amount, error } = this.state;
        const options = [
            'Monthly', 'Annual'
        ]
        const defaultOption = options[0]
        return (
            <>
                <div className="margin-bottom-1"><b>Add a new subscription</b></div>
                <form onSubmit={this.onFormSubmit}>
                    <div className="inline-block margin-right-1">
                        <label>Name</label>
                        <input
                            type="text"
                            name="name"
                            value={name}
                            onChange={this.handleChange} />
                    </div>
                    <div className="inline-block margin-right-1">
                        <label>Amount</label>
                        <input
                            type="number"
                            name="Amount"
                            value={Amount}
                            onChange={this.handleChange} />
                    </div>
                    <div id="newSubErrorMessage" className="margin-bottom-half red-color">{error}</div>
                    <div className="flex-display margin-bottom">
                        <div className="inline-block margin-right-1">
                            <Dropdown options={options} onChange={this.onRenewalType} value={defaultOption} placeholder="Select Renewal type" />
                        </div>
                        <div className="inline-block">
                            <button type="submit" className="mybutton">
                                Submit
                        </button>
                        </div>
                    </div>
                </form>
            </>
        );
    }
}

export default Form;
