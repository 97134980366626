import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import Features from "./Features";

{/* <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        ></SectionHeader> */}
function FeaturesSection(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            size={2}
            spaced={true}
            className="text-center"
          ></SectionHeader>
        <Features
          items={[
            {
              title: "Simple monthly and yearly budgets",
              description:
                "No more spreadsheets or messy notes to track your expenses. Save your recurring expenses or subscription details and view your total expenditure. Easily switch between monthly and yearly amounts.",
              image: "assets/budget_subscription_tracking.svg"
            },
            // image: "/assets/hassle_free_subscription_tracking.svg"
            {
              title: "Insights that save time and money",
              description:
                "Easily identify your most expensive subscriptions. Graph visualization and sorting help you get instant insights on where your money is spent the most. Get Insights in both monthly and annual modes to plan for the short and long terms.",
              image: "/assets/insights_subscription_tracking.svg"
            },
            {
              title: "Anywhere, Anytime",
              description:
                "Access your subscription details from any device with your HappyDebits account. Your subscription data gets synced automatically.",
              image:
                "/assets/cloud_subscription_tracking.svg"
            },
   
            /* {
              title: "Budget in your fingertips",
              description:
                "View your total monthly and annual expenditures effortlessly",
              image: "assets/budget_subscription_tracking.svg"
            } */
          ]}
        ></Features>
      </Container>
    </Section>
  );
}

export default FeaturesSection;
