import React, { Component, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortAlphaDown, faSortAlphaDownAlt, faSortAmountUp, faSortAmountDownAlt, faSortAmountDown, faSort, faTrash } from '@fortawesome/free-solid-svg-icons'
import Table from 'react-bootstrap/Table';

const trashIcon = <FontAwesomeIcon icon={faTrash} />

const TableHeader = props => {
  let nameSorter = faSort;
  let amountSorter = faSort;

  let nameMode = props.sortState.nameSort;
  let amountMode = props.sortState.AmountSort;

  const isInitialSort = props.sortState.isInitialSort;
  if(isInitialSort === "nameSort"){
     amountSorter = faSort;
     if(nameMode==="ascending")
        nameSorter = faSortAlphaDown
      else if(nameMode==="descending")
        nameSorter = faSortAlphaDownAlt
  }else if(isInitialSort === "AmountSort"){
    nameSorter = faSort;
    if(amountMode==="ascending")
    amountSorter = faSortAmountDown
    else if(amountMode==="descending")
    amountSorter = faSortAmountDownAlt
  }

  const AZiconElement = <FontAwesomeIcon title="Sort by subscription name" icon={nameSorter} />
  const NOiconElement = <FontAwesomeIcon title="Sort by amount" icon={amountSorter} />


  let deleteCol;
  if (props.isManageMode) {
    deleteCol = <th>Actions</th>;
  } else {
    deleteCol = "";
  }
  return (
    <thead>
      <tr>
        <th onClick={e => props.toggleAndSaveSort(e, 'name')}>Subscription {AZiconElement}</th>
        <th onClick={e => props.toggleAndSaveSort(e, 'Amount')}>Amount {NOiconElement}</th>
        {deleteCol!==""?deleteCol:null}
      </tr>
    </thead>
  );
}

const TableBody = props => {
  let total = 0;
  let isAnnual = props.isAnnual;
  let rows = props.subscriptionData.map((sub, index) => {
    let displayAmount = sub.Amount;
    if (isAnnual && sub.renewal === 'Annual' || (!isAnnual && sub.renewal === 'Monthly')) {
      total += sub.Amount;
    } else if (isAnnual && sub.renewal === 'Monthly') {
      displayAmount = (sub.Amount * 12);
      total += displayAmount;
    } else if (!isAnnual && sub.renewal === 'Annual') {
      displayAmount = (sub.Amount / 12)
      total += displayAmount;
    }
    let deleteCol;
    if (props.isManageMode) {
      deleteCol = <td><button className="delButton" title="Delete" onClick={() => props.removeSubscription(index)}>
        {trashIcon}</button></td>;
    } else {
      deleteCol = "";
    }
    return (
      <tr key={index}>
        <td>{sub.name}</td>
        <td>{displayAmount.toFixed(2)}</td>
        {deleteCol!==""?deleteCol:null}
      </tr>
    );
  });
  if (props.isManageMode) {
    rows.push(<tr key="total">
      <td><b>Total</b></td>
      <td colSpan="2"><b>{total.toFixed(2)}</b></td>
    </tr>);
  } else {
    rows.push(<tr key="total">
      <td><b>Total</b></td>
      <td><b>{total.toFixed(2)}</b></td>
    </tr>);
  }

  return <tbody>{rows}</tbody>;
}

class TableWrapper extends Component {
  render() {
    const { subscriptionData, removeSubscription, isAnnual, isManageMode } = this.props;
    return (
      <Table striped bordered hover>
        <TableHeader isManageMode={isManageMode} toggleAndSaveSort={this.props.toggleAndSaveSort} sortState={this.props.sortState} />
        <TableBody isManageMode={isManageMode} subscriptionData={subscriptionData} removeSubscription={removeSubscription} isAnnual={isAnnual} />
      </Table>
    );
  }
}

export default TableWrapper;
